import axios, { RawAxiosRequestHeaders } from 'axios';

import { ApiResponseError } from './api.service.types';
import { ApiEndpoints, ApiMapTypes } from './apiMapTypes';

const callApiMethod = <Endpoint extends ApiEndpoints, Method extends 'get' | 'post'>(
  method: Method,
  endpoint: Endpoint,
  token: string | null,
  params?: ApiMapTypes[Endpoint][Method]['params'],
  baseUrl?: string,
  paramsSerializer?: any,
): Promise<ApiMapTypes[Endpoint][Method]['response']> => {
  const headers: RawAxiosRequestHeaders = {};
  if (token) {
    headers.Authorization = token;
  }

  return axios({
    method,
    url: `${baseUrl || process.env.REACT_APP_API_DOMAIN}/${endpoint}/`,
    params: method === 'get' ? params : undefined,
    data: method !== 'get' ? params : undefined,
    paramsSerializer: paramsSerializer,
    headers,
  });
};

export type RequestParams<Endpoint extends ApiEndpoints, Method extends 'get' | 'post', R> = {
  authToken: string | null;
  method: Method;
  endpoint: Endpoint;
  params?: ApiMapTypes[Endpoint][Method]['params'];
  mapper?: (response: ApiMapTypes[Endpoint][Method]['response']) => R;
  baseUrl?: string;
  paramsSerializer?: any;
};

const request = async <Endpoint extends ApiEndpoints, Method extends 'get' | 'post', R>({
  method,
  endpoint,
  params = {},
  mapper = (res) => res as R,
  authToken,
  baseUrl,
  paramsSerializer,
}: RequestParams<Endpoint, Method, R>) => {
  return callApiMethod(method, endpoint, authToken, params, baseUrl, paramsSerializer)
    .then(({ data }: any) => data)
    .then(mapper)
    .catch((e) => {
      throw {
        code: e?.response?.status || 0,
        message: e?.response?.data?.detail || 'Error',
        data: e?.response?.data,
      } as ApiResponseError;
    });
};

export { request };
